import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';

export default function Footer() {
    return (
        <div className='bg-dark'>
            <div className='container mt-1 px-4 py-5 bg-dark text-white'>
                <div className='row justify-content-center'>
                    <div className='col-sm-4 text-center '>
                        <img
                            className='img-responsive'
                            src='https://rogamus.de/wp-content/uploads/erbistum.png'
                            alt=''
                            width='200px'
                        />
                        <p className='text-muted mb-4'>
                            <small>© THE CORPUS CHRISTI MOVEMENT 2021</small>
                        </p>
                        <small>
                            <a
                                className='text-white nounderline'
                                href='/en/imprint'
                            >
                                Imprint{' '}
                            </a>{' '}
                            |{' '}
                            <a
                                className=' text-white nounderline'
                                href='/en/privacy'
                            >
                                Privacy
                            </a>
                        </small>
                    </div>
                    <div className='col-sm-4 text-center py-4'>
                        <img
                            alt=''
                            className='img-responsive'
                            src='https://rogamus.de/wp-content/uploads/berufen-Logo_wei%C3%9F.png'
                            width='70px'
                        />
                        <p className='mt-3'>
                            <small>
                                <a
                                    className='text-muted nounderline'
                                    href='https://berufen.de'
                                    target='_blank'
                                    rel='noreferrer'
                                ></a>
                            </small>
                        </p>
                    </div>{' '}
                    <div className='col-sm-4 text-center py-4'>
                        <p className='text-muted'>
                            Follow Us <br /> <br />
                            <a
                                className='text-white nounderline me-3 h3'
                                href='#'
                                target='_blank'
                                rel='noreferrer'
                            >
                                <FontAwesomeIcon icon={faFacebook} />
                            </a>
                            <a
                                className='text-white nounderline h3'
                                href='#'
                                target='_blank'
                                rel='noreferrer'
                            >
                                <FontAwesomeIcon icon={faInstagram} />
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
