import React from 'react';
import Header from './Header';

import Footer from './Footer';

// markup
const Container = ({ children }) => {
	return (
		<div className='bg-light'>
			<Header />

			<div className='container px-2 px-md-4 py-4 py-md-5'>{children}</div>
			<Footer />
		</div>
	);
};

export default Container;
