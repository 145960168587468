import React from 'react';
import { Link } from 'gatsby';
import navigationStyles from './Navigation.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function NavigationItem({ name, url }) {
    return (
        <li className='nav-item px-lg-3'>
            <Link
                activeClassName=''
                className='fw-medium fs-5 nav-link nounderline text-white'
                to={url}
            >
                {name}
            </Link>
        </li>
    );
}

function DropdownItem({ name, url }) {
    return (
        <li className='dropdown-item px-3'>
            <Link activeClassName='' className='nav-link nounderline ' to={url}>
                {name}
            </Link>
        </li>
    );
}

function NavigationDropdownItem({ name, url, children }) {
    return (
        <li class='nav-item px-lg-3 dropdown'>
            <button
                class='fw-medium fs-5 nav-link dropdown-toggle border-0 btn text-white'
                href='#'
                id='navbarDropdown'
                data-bs-toggle='dropdown'
                aria-expanded='false'
            >
                {name}
            </button>
            <ul
                class={
                    'dropdown-menu shadow-sm border-0 ' +
                    navigationStyles.menuMargin
                }
                aria-labelledby='navbarDropdown'
            >
                {children}
            </ul>
        </li>
    );
}

export default function Navigation() {
    return (
        <nav className='navbar navbar-expand-lg p-0 w-100 d-flex justify-content-between'>
            <Link to='/' className='d-lg-none'>
                <img
                    src={'../../SVG/Logo.svg'}
                    alt=''
                    height='50'
                    width='157'
                />
            </Link>
            <button
                class='navbar-toggler p-2 border-0 rounded-0 align-self-end'
                type=''
                data-bs-toggle='collapse'
                data-bs-target='#navbarNav'
                aria-controls='navbarNav'
                aria-expanded='false'
                aria-label='Toggle navigation'
            >
                <div className='h1 m-0'>
                    <FontAwesomeIcon className='text-white' icon='bars' />
                </div>
            </button>

            <div class='collapse navbar-collapse ' id='navbarNav'>
                <ul className='navbar-nav mx-auto'>
                    <NavigationItem name='Home' url='/en' />
                    <NavigationDropdownItem name='About us'>
                        <DropdownItem
                            name='Spirituality'
                            url='/en/spirituality'
                        />
                        <DropdownItem
                            name='Contact Persons'
                            url='/contact-persons'
                        />
                    </NavigationDropdownItem>
                    <NavigationDropdownItem name='News'>
                        <DropdownItem
                            name='Newsletter'
                            url='/category/newsletter'
                        />
                        <DropdownItem
                            name='Retreats'
                            url='/category/retreats'
                        />
                    </NavigationDropdownItem>
                    <NavigationDropdownItem name='Resources'>
                        <DropdownItem
                            name='St. Teresa of Calcutta'
                            url='/en/st-teresa-of-calcutta'
                        />
                        <DropdownItem name='Prayers' url='/en/prayers' />
                        <DropdownItem name='MC Family' url='/en/mc-family' />
                        <DropdownItem name='Publications' url='#' />
                    </NavigationDropdownItem>
                </ul>
            </div>
        </nav>
    );
}
