import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import headerStyles from './Header.module.scss';
import Navigation from './Navigation';

function HeaderItem({ name, url, icon, iconSize }) {
    return (
        <a
            href={url}
            className='headeritem nounderline text-white align-self-center'
            target='_blank'
            rel='noreferrer'
        >
            <div className={'d-none h3 d-md-inline ' + iconSize}>
                <FontAwesomeIcon icon={icon} />
            </div>
            <div className='d-md-none h2 mx-4 mb-0'>
                <FontAwesomeIcon icon={icon} />{' '}
            </div>
            <span className='d-none d-md-inline'>{name}</span>
        </a>
    );
}

function Header() {
    return (
        <div class={'container-flex bg-primary pb-1  shadow-sm'}>
            <div class={'container-flex bg-white pb-2 ' + headerStyles.shadow}>
                <div
                    class={
                        'container-flex bg-primary pb-1 ' + headerStyles.shadow
                    }
                >
                    <div
                        class={
                            'container-flex bg-white pb-2 ' +
                            headerStyles.shadow
                        }
                    >
                        <div
                            class={
                                'container-flex bg-primary pb-1 ' +
                                headerStyles.shadow
                            }
                        >
                            <div class='container d-flex justify-content-between  pt-3 pb-3 px-3 '>
                                <Link to='/' className='d-lg-block d-none'>
                                    <img
                                        src={'/SVG/Logo.svg'}
                                        alt=''
                                        height='50'
                                        width='157'
                                    />
                                </Link>
                                <Navigation />
                                <div class=' d-lg-flex justify-content-start d-none '>
                                    <HeaderItem
                                        url='mailto:corpxti@gmail.com'
                                        name=''
                                        icon='envelope'
                                        iconSize='me-1'
                                    />
                                    <HeaderItem
                                        url='tel:+0'
                                        name=''
                                        icon='phone'
                                        iconSize='me-1 ms-4'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;
