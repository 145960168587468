import React from 'react';
import Container from '../components/Container';
import { Link } from 'gatsby';

export default function Page({ pageContext: { data } }) {
	return (
		<Container>
			<div class='row justify-content-md-center'>
				<div className='col-md-9'>
					<div className='pb-2 '>
						<Link className='text-muted text-decoration-none' to='/prayers'>
							Back to all Prayers
						</Link>
					</div>
					<div className='relative d-flex flex-column rounded-3 shadow bg-white overflow-hidden'>
						{data.featuredImage?.node?.mediaItemUrl && (
							<div
								className='w-100 overflow-hidden d-flex'
								style={{ maxHeight: '20rem' }}
							>
								<img
									className='img-fluid w-100 align-self-center'
									src={data.featuredImage.node.mediaItemUrl}
									alt=''
								/>
							</div>
						)}
						<h1 className='p-4 mb-0 border-bottom text-center'>{data.title}</h1>
						<div
							className='p-4 '
							dangerouslySetInnerHTML={{ __html: data.content }}
						></div>
					</div>
				</div>
			</div>
		</Container>
	);
}
